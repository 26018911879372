// JavaScript Document

$(function(){
    initMobileNavigation();
    
    $(document).on('click','.tvt-smoothscroll', function(e) {
		e.preventDefault();
		var target = "#" + this.getAttribute('data-target');
		$('html, body').animate({
			scrollTop: $(target).offset().top - 80
		}, 500);
    });

});
function initMobileNavigation(){
    $('#menu-toggle').on('click', function(){
        
        if ($('.navigation-links').hasClass('active')){
            
            $('.navigation-links').toggleClass('active');

            setTimeout( function(){
                $('.brand-logo').toggleClass('active');
                $('.navigation-links').toggleClass('open-slide-in');
            } , 300 );
            
            setTimeout( function(){
                $('#menu-toggle').toggleClass('is-active');
            } , 600 );
            
        } else{
            $('.navigation-links').toggleClass('open-slide-in');
            $('.brand-logo').toggleClass('active');
            setTimeout( function(){
                $('.navigation-links').toggleClass('active');
                $('#menu-toggle').toggleClass('is-active');
                
            } , 400 );

        }
    });
}

function clearNav() {

  if ($('.navigation-links').hasClass('active')) {
    $('.navigation-links').removeClass('active');
    $('.brand-logo').removeClass('active');
    $('.navigation-links').removeClass('open-slide-in');
    $('#menu-toggle').removeClass('is-active');
  }
}

function initializeLightGallery(lg) {
    $('#lightGallery').lg({
      pager: true,
      selector: '.item'
    });
}

function removeLg() {
  document.querySelector('body').classList.remove('lg-on');
  $('.lg-backdrop').remove();
  $('.lg-outer').remove();
}
